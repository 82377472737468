import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CreateCompany, useGetCompanyParents } from "../../api/CompanyApi";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import When from "../../components/When";
import { CompanyType, Role } from "../../core/enums/enums";
import { createNewCompany, validateOrgNo } from "../../core/helpers/companyHelper";
import { toastSuccess } from "../../core/helpers/toastHelper";
import { ICompany, ICreateCompany } from "../../core/interfaces/company";
import { AppQueryKey } from "../../core/queryKeys";
import useAccessCheck from "../../hooks/useAccessCheck";
import { useGetUser } from "../../hooks/useAuth";
import useUpdatableObject from "../../hooks/useUpdatableObject";
import { addToast } from "../../store/slices/uiSlice";



const Hr: React.FC<{ title: string, children: JSX.Element | JSX.Element[] }> = ({ title, children }) => {
    return <><Row>
        <Col sm={1}>
            <b>{title}</b>
        </Col>
        <Col sm={11}>
            <hr />
        </Col>
    </Row>
        <Row style={{ marginLeft: 1 }}>
            {children}
        </Row>
    </>
}

const NewCompanyModal: React.FC<{ show: boolean, hide: () => void }> = ({ show, hide }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const useQuery = useQueryClient()
    const [, checkRole] = useAccessCheck();
    const user = useGetUser();
    const [updated, setUpdated, isEdited, submit, loading] = useUpdatableObject<ICreateCompany, ICompany>(createNewCompany(user.parentCompanyId), CreateCompany, {
        getErrorToast: (status: number): { title: string, message: string } => {
            const info = { title: t('NewCompanyModal.ERROR_OCCURRED_1'), message: t('NewCompanyModal.PLEASE_TRY_AGAIN_SOON_1') }
            if (status === 409) {
                info.message = t('NewCompanyModal.ORGANIZATION_NUMBER_ALREADY_EXISTS_1')
            }
            return info;
        },
    });
    const [errors, setErrors] = useState({
        orgNo: true,
        name: true,
        city: true
    });

    useEffect(() => {
        if (show) {
            setUpdated(createNewCompany(user.parentCompanyId));
        }
    }, [show, setUpdated])

    const validate = (company: ICreateCompany, name: string) => {
        switch (name) {
            case 'name':
                errors.name = !company.name.length;
                break;
            case 'orgNo':
                if (company.orgNo.length > 6 && !company.orgNo.includes("-")) {
                    company.orgNo = company.orgNo.slice(0, 6) + '-' + company.orgNo.slice(6);
                }
                errors.orgNo = !validateOrgNo(company.orgNo);
                break;
            case 'city':
                errors.city = !company.city.length;
                break;
        }

        setErrors(errors)
        return company;
    }

    const handleSubmit = () => {
        submit().then(company => {
            hide();
            useQuery.invalidateQueries([AppQueryKey.COMPANIES]);
            useQuery.invalidateQueries([AppQueryKey.COMPANY_PARENTS]);          
            dispatch(addToast(toastSuccess(t('NewCompanyModal.SUCCESS_1'), company.name + t('NewCompanyModal.CREATED_1'))));
        })
    }

    const [companyParents] = useGetCompanyParents();

    const commonProps = {
        isEditMode: true,
        object: updated,
        errorObject: errors,
        setValue: setUpdated,
        onChangeMiddleWare: validate,
    }
    return (
        <ModalForm size={'lg'} show={show} hide={hide} title={t('NewCompanyModal.CREATE_NEW_COMPANY_1')} isEdited={isEdited} submit={handleSubmit}
            isLoading={loading} disableSubmit={!!Object.values(errors).filter(s => s).length}>
            <Hr title={t('NewCompanyModal.COMPANY_INFO_1')}>
                <Row>
                    <Col md={6}>
                        <FormBase row={false} controlId={'companyName'} title={t('NewCompanyModal.COMPANY_NAME_1')}
                            className={'pb-2'}>
                            <GenericInput name={'name'} {...commonProps} />
                        </FormBase>
                    </Col>
                    <Col md={6}>
                        <FormBase row={false} controlId={'orgNo'} title={t('NewCompanyModal.ORGANIZATION_NUMBER_1')}
                            className={'pb-2'}>
                            <GenericInput name={'orgNo'} {...commonProps} description={t('NewCompanyModal.FORMAT_1')} />
                        </FormBase>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormBase row={false} controlId={'city'} title={t('NewCompanyModal.CITY_1')}
                            className={'pb-2'}>
                            <GenericInput name={'city'} {...commonProps} />
                        </FormBase>
                    </Col>
                    <Col md={6}>
                        <FormBase row={false} controlId={'companyType'} title={t('NewCompanyModal.TYPE_1')}
                            className={'pb-2'}>
                            <GenericInput type={'select'} name={'companyType'} {...commonProps} disabled={!checkRole(Role.SUPERADMIN)}>
                                <option value={CompanyType.COMPANY}>{t('NewCompanyModal.COMPANY_SUBSIDIARY_1')}</option>
                                <option value={CompanyType.PARENT}>{t('NewCompanyModal.PARENT_COMPANY_1')}</option>
                            </GenericInput>
                        </FormBase>
                    </Col>
                </Row>
            </Hr>
            <When condition={updated.companyType === CompanyType.COMPANY}>
                <Hr title={t('NewCompanyModal.GROUP_INFO_1')}>
                    <Row>
                        <Col md={6}>
                            <FormBase row={false} controlId={'parentCompanyId'} title={t('NewCompanyModal.PARENT_COMPANY_2')}
                                className={'pb-2'}>
                                <GenericInput type={'select'} name={'parentCompanyId'} {...commonProps} disabled={!checkRole(Role.SUPERADMIN)}>
                                    {[
                                        <option value={''} key={''}>{t('NewCompanyModal.NOT_SPECIFIED_1')}</option>,
                                        ...companyParents.map((v, index) => <option value={v.id} key={v.id}>{v.name}</option>)
                                        
                                    ]}
                                </GenericInput>
                            </FormBase>
                        </Col>
                        {/*    <Col md={6}>*/}
                        {/*        <FormBase row={false} controlId={'region'} title={t('NewCompanyModal.REGION/GROUP_1')}*/}
                        {/*            className={'pb-2'}>*/}
                        {/*            <GenericInput type={'select'} name={'region'} {...commonProps}>*/}
                        {/*            </GenericInput>*/}
                        {/*        </FormBase>*/}
                        {/*    </Col>*/}
                    </Row>
                </Hr>
            </When>
            <Hr title={t('NewCompanyModal.GROUP_INFO_2')}>
                <Row>
                    <Col md={6}>
                        <FormBase row={false} controlId={'switchFeatureSkills'} title={''}>
                            <Form.Check
                                disabled={!checkRole(Role.SUPERADMIN)}
                                type="switch"
                                id="switchFeatureSkills"
                                label={t('NewCompanyModal.FEATURE_SKILLS_1')}
                                onChange={e => { }}
                                onClick={(e: any) => {
                                    updated.featureSkills = e.target.checked;
                                }}
                            />
                        </FormBase>
                    </Col>
                </Row>
            </Hr>
        </ModalForm>
    )
}


export default NewCompanyModal
