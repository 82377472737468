import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useGetCompanyParents, useUpdateCompany } from "../../api/CompanyApi";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import When from "../../components/When";
import { CompanyType, Role } from "../../core/enums/enums";
import { validateOrgNo } from "../../core/helpers/companyHelper";
import { ICompany, ICreateCompany } from "../../core/interfaces/company";
import { AppQueryKey } from '../../core/queryKeys';
import useAccessCheck from '../../hooks/useAccessCheck';


const Hr: React.FC<{ title: string, children: JSX.Element | JSX.Element[] }> = ({ title, children }) => {
    return <>
        <Row>
            <Col sm={1}>
                <b>{title}</b>
            </Col>
            <Col sm={11}>
                <hr />
            </Col>
        </Row>
        <Row style={{ marginLeft: 1 }}>
            {children}
        </Row>
    </>
}

const EditCompanyModal: React.FC<{
    show: boolean,
    hide: () => void,
    company: ICompany,
    viewMode?: boolean
}> = ({
    show,
    hide,
    company,
    viewMode = false
}) => {
        const { t } = useTranslation();
        const useQuery = useQueryClient();
        const [, checkRole] = useAccessCheck();
        const [update, isUpdating] = useUpdateCompany(company?.id);
        const [updatedObject, setUpdatedObject] = useState({ ...company, parentCompanyId: company?.parentCompany?.id } as ICreateCompany);

        useEffect(() => {
            useQuery.invalidateQueries([AppQueryKey.COMPANIES]);
            useQuery.invalidateQueries([AppQueryKey.COMPANY_PARENTS]);

            setUpdatedObject({ ...company, parentCompanyId: company?.parentCompany?.id } as ICreateCompany)
        }, [company])

        const [companyParents,] = useGetCompanyParents();

        const [errors, setErrors] = useState({
            orgNo: false,
            name: false,
            city: false
        });

        const validate = (company: ICreateCompany, name: string) => {
            switch (name) {
                case 'name':
                    errors.name = !company.name.length;
                    break;
                case 'orgNo':
                    if (company.orgNo.length > 6 && !company.orgNo.includes("-")) {
                        company.orgNo = company.orgNo.slice(0, 6) + '-' + company.orgNo.slice(6);
                    }
                    errors.orgNo = !validateOrgNo(company.orgNo);
                    break;
                case 'city':
                    errors.city = !company.city.length;
                    break;
            }

            setErrors(errors)
            return company;
        }

        const commonProps = {
            isEditMode: !viewMode,
            object: updatedObject,
            errorObject: errors,
            setValue: setUpdatedObject,
            onChangeMiddleWare: validate
        }

        if (company == null) return <></>

        return (
            <ModalForm
                size={'lg'}
                show={show}
                hide={hide}
                title={viewMode ? "" : t('EditCompanyModal.EDIT_COMPANY_1')}
                isEdited={() => false}
                submit={() => {
                    if (updatedObject.parentCompanyId === "")
                        updatedObject.parentCompanyId = null;

                    const updated = _.cloneDeep(updatedObject) as ICompany;
                    !viewMode && update(updated).then(_ => hide());
                }}
                submitText={t('EditCompanyModal.SAVE_1')}
                disableSubmit={viewMode || !!Object.values(errors).filter(s => s).length}
                isLoading={isUpdating}>

                <Hr title={t('EditCompanyModal.COMPANY_INFO_1')}>
                    <Row>
                        <Col md={6}>
                            <FormBase row={false} controlId={'companyName'} title={t('EditCompanyModal.COMPANY_NAME_1')}
                                className={'pb-2'}>
                                <GenericInput name={'name'} {...commonProps} />
                            </FormBase>
                        </Col>
                        <Col md={6}>
                            <FormBase row={false} controlId={'orgNo'} title={t('EditCompanyModal.ORGANIZATION_NUMBER_1')}
                                className={'pb-2'}>
                                <GenericInput name={'orgNo'} {...commonProps} description={t('EditCompanyModal.FORMAT_PATTERN_1')} />
                            </FormBase>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormBase row={false} controlId={'city'} title={t('EditCompanyModal.CITY_1')}
                                className={'pb-2'}>
                                <GenericInput name={'city'} {...commonProps} />
                            </FormBase>
                        </Col>
                        <Col md={6}>
                            <FormBase row={false} controlId={'companyType'} title={t('EditCompanyModal.TYPE_1')}
                                className={'pb-2'}>
                                <GenericInput type={'select'} name={'companyType'} {...commonProps} disabled={!checkRole(Role.SUPERADMIN)}>
                                    <option value={CompanyType.COMPANY}>{t('EditCompanyModal.COMPANY_SUBSIDIARY_1')}</option>
                                    <option value={CompanyType.PARENT}>{t('EditCompanyModal.PARENT_COMPANY_1')}</option>
                                </GenericInput>
                            </FormBase>
                        </Col>
                    </Row>
                </Hr>
                <When condition={updatedObject.companyType === CompanyType.COMPANY}>
                    <Hr title={t('EditCompanyModal.GROUP_INFO_1')}>
                        <Row>
                            <Col md={6}>
                                <FormBase row={false} controlId={'parentCompanyId'} title={t('EditCompanyModal.PARENT_COMPANY_2')}
                                    className={'pb-2'}>
                                    <GenericInput type={'select'} name={'parentCompanyId'} {...commonProps} disabled={!checkRole(Role.SUPERADMIN)}>
                                        {[
                                            <option value={''} key={''}>{t('EditCompanyModal.PARENT_COMPANY_3')}</option>,
                                            ...(companyParents?.map((v) => <option value={v.id} key={v.id}>{v.name}</option>) || [])
                                        ]}
                                    </GenericInput>
                                </FormBase>
                            </Col>
                            {/*    <Col md={6}>*/}
                            {/*        <FormBase row={false} controlId={'region'} title={t('EditCompanyModal.REGION/GROUP_1')}*/}
                            {/*            className={'pb-2'}>*/}
                            {/*            <GenericInput type={'select'} name={'region'} {...commonProps}>*/}
                            {/*            </GenericInput>*/}
                            {/*        </FormBase>*/}
                            {/*    </Col>*/}
                        </Row>
                    </Hr>
                </When>
                <Hr title={t('EditCompanyModal.GROUP_INFO_2')}>
                    <Row>
                        <Col md={6}>
                            <FormBase row={false} controlId={'switchFeatureSkills'} title={''}>
                                <Form.Check
                                    disabled={!checkRole(Role.SUPERADMIN)}
                                    defaultChecked={updatedObject.featureSkills}
                                    type="switch"
                                    id="switchFeatureSkills"
                                    label={t('EditCompanyModal.FEATURE_SKILLS_1')}
                                    onChange={e => { }}
                                    onClick={(e: any) => {
                                        updatedObject.featureSkills = e.target.checked;
                                    }}
                                />
                            </FormBase>
                        </Col>
                    </Row>
                </Hr>
            </ModalForm>
        )
    }

export default EditCompanyModal
