import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from "i18next";
import { authenticate } from '../../api/AuthApi';
import { getCompanyLogo } from "../../api/CompanyApi";
import { GetUserById } from "../../api/UserApi";
import { URL_AUTHENTICATE } from "../../api/endpoints/endpoints";
import { clearTokens, getDecodedTokenData, isTokenValid, setTokens } from '../../core/auth/AuthToken';
import { CompanyRole, HTTP_STATUS, Role } from '../../core/enums/enums';
import { toastError } from '../../core/helpers/toastHelper';
import { IResponse } from "../../core/interfaces/common";
import { IUserDetails } from "../../core/interfaces/user";
import {
    STATUS_FORBIDDEN,
    STATUS_IDLE,
    STATUS_PENDING,
    STATUS_REJECTED,
    STATUS_SUCCEEDED,
    STATUS_UNAUTHORIZED
} from '../types';
import { addErrorGeneralToast, addToast } from './uiSlice';


interface IAuthResponse {
    role: Role;
    userId: string;
    firstName: string;
    lastName: string;
    companyId: string;
    companyName: string;
    companyRole: CompanyRole;
    companyUserId: string;
    parentCompanyId: string | null;
    hasMultipleCompanies: boolean;
    featureSkills: boolean;
}

interface IAuthState {
    authenticated: boolean;
    status: 'idle' | 'pending' | 'succeeded' | 'rejected' | 'unauthorized' | 'forbidden' | 'authSuccess';
    userId: string;
    firstName: string;
    lastName: string;
    role: Role | null;
    companyId: string;
    companyName: string;
    companyRole: CompanyRole;
    companyLogoLoading: boolean;
    companyLogo: string;
    companyUserId: string;
    parentCompanyId: string | null;
    hasMultipleCompanies: boolean;
    featureSkills: boolean;
}

const initialState: IAuthState = {
    authenticated: false,
    status: STATUS_IDLE,
    userId: "",
    firstName: "",
    lastName: "",
    role: null,
    companyId: "",
    companyRole: CompanyRole.READER,
    companyName: "",
    companyLogoLoading: false,
    companyLogo: "",
    companyUserId: "",
    parentCompanyId: null,
    hasMultipleCompanies: false,
    featureSkills: false
}

export const login = createAsyncThunk(URL_AUTHENTICATE, async (loginRequest: IUserLoginRequest, {
    dispatch,
    rejectWithValue
}) => {
    try {
        // Authenticate
        const authResponse = await authenticate(loginRequest);
        if (authResponse.status !== HTTP_STATUS.OK_200) {
            if (authResponse.status === HTTP_STATUS.FORBIDDEN_403) {
                dispatch(addToast(toastError(i18n.t('authSlice.LOGIN_FAILED_1'), i18n.t('authSlice.PERMISSION_DENIED_1'))));
            }
            if (authResponse.status === HTTP_STATUS.INTERNAL_SERVER_ERROR_500) {
                dispatch(addErrorGeneralToast());
            }
            return rejectWithValue(authResponse.status);
        }

        // save tokens
        const tokens = authResponse.data as ITokenResponse;
        setTokens(tokens.token, tokens.refreshToken);

        // Get User details
        const userDataResponse = await dispatch(getUserDetails(tokens));
        if (userDataResponse.payload === HTTP_STATUS.NOT_FOUND_404) {
            dispatch(addErrorGeneralToast());
            return rejectWithValue(userDataResponse.payload);
        }
        if (userDataResponse.payload === HTTP_STATUS.FORBIDDEN_403) {
            return rejectWithValue(userDataResponse.payload);
        }
    } catch (error: any) {
        dispatch(addErrorGeneralToast());
        throw new Error(error);
    }
});

export const getUserDetails = createAsyncThunk("GET_USER_DETAILS", async (tokens: ITokenResponse, {
    dispatch,
    rejectWithValue
}) => {
    const decodedToken = getDecodedTokenData(tokens.token);
    if (!decodedToken) {
        return rejectWithValue(HTTP_STATUS.UNAUTHORIZED_401);
    }
    if (!isTokenValid(decodedToken)) {
        dispatch(addToast(toastError(i18n.t('authSlice.ERROR_OCCURRED_1'), i18n.t('authSlice.NOT_AUTHORIZED_CONTACT_SUPPORT_1'))));
        return rejectWithValue(HTTP_STATUS.FORBIDDEN_403);
    }

    const response: IResponse<IUserDetails> = await GetUserById(decodedToken.sub);
    if (response.status !== 200) return rejectWithValue(response.status);

    const userDetails = response.data;
    userDetails.role = decodedToken.role ? decodedToken.role as Role : Role.USER;
    userDetails.hasMultipleCompanies = Array.isArray(decodedToken.company_id) as boolean;

    if (userDetails.role === Role.SUPERADMIN) {
        userDetails.companyId = "";
        userDetails.companyName = "";
        userDetails.companyUserId = "";
        userDetails.companyRole = CompanyRole.ADMIN;
        userDetails.parentCompanyId = null;
        userDetails.featureSkills = true;
        return userDetails as never as IAuthResponse;
    }
    // fetch company logo
    await dispatch(getLogo(userDetails.companyId));

    return userDetails as never as IAuthResponse;
});

export const getLogo = createAsyncThunk("GET_COMPANY_LOGO", async (companyId: string) => {
    const logoResponse = await getCompanyLogo(companyId);
    if (logoResponse && logoResponse.data) {
        return URL.createObjectURL(logoResponse.data);
    }
    return "";
});

export const setSamgSelectedCompanyThunk = createAsyncThunk("", async (companyId: string, { dispatch }) => {
    await dispatch(getLogo(companyId));
    return {
        companyId: companyId,
    }
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => initialState,
        changeCompany: (state, action) => {
            state.companyId = action.payload.id;
            state.companyName = action.payload.name;
            state.featureSkills = action.payload.featureSkills;
            if (state.role === Role.SUPERADMIN)
                state.parentCompanyId = (action.payload.companyType === "Parent" || !!action.payload.parentCompany)
                    ? action.payload.parentCompany?.id ?? action.payload.id
                    : undefined;
        },
        changeCompanyUser: (state, action) => {
            state.companyUserId = action.payload?.id;
            if (action.payload)
                state.companyRole = action.payload?.roleSymbol;
        }
    },
    extraReducers(builder) {
        builder
            // LOGIN AND GET USER DATA
            .addCase(login.pending, (state) => {
                state.status = STATUS_PENDING;
            })
            .addCase(login.rejected, (state, action) => {
                switch (action.payload) {
                    case HTTP_STATUS.UNAUTHORIZED_401:
                    case STATUS_UNAUTHORIZED:
                        state.status = STATUS_UNAUTHORIZED;
                        break;
                    case HTTP_STATUS.FORBIDDEN_403:
                    case STATUS_FORBIDDEN:
                        state.status = STATUS_FORBIDDEN;
                        break;
                    default:
                        state.status = STATUS_IDLE;
                }
            })
            .addCase(login.fulfilled, (state) => {
                state.status = STATUS_SUCCEEDED;
            })

            // GET_USER_DETAILS
            .addCase(getUserDetails.pending, (state) => {
                state.status = STATUS_PENDING;
            })
            .addCase(getUserDetails.rejected, (state, { payload }) => {
                if (payload === HTTP_STATUS.FORBIDDEN_403) {
                    state.status = STATUS_FORBIDDEN;
                } else {
                    state.status = STATUS_REJECTED;
                }
                clearTokens();
            })
            .addCase(getUserDetails.fulfilled, (state, { payload }) => {
                state.authenticated = true;
                state.status = STATUS_SUCCEEDED;
                state.role = payload.role;
                state.userId = payload.userId;
                state.firstName = payload.firstName;
                state.lastName = payload.lastName;
                state.companyId = payload.companyId;
                state.companyName = payload.companyName;
                state.companyRole = payload.companyRole;
                state.companyUserId = payload.companyUserId;
                state.parentCompanyId = payload.parentCompanyId;
                state.hasMultipleCompanies = payload.hasMultipleCompanies;
                state.featureSkills = payload.featureSkills;
            })

            // GET_COMPANY_LOGO
            .addCase(getLogo.pending, (state) => {
                state.companyLogoLoading = true;
            })
            .addCase(getLogo.fulfilled, (state, { payload }) => {
                state.companyLogo = payload;
                state.companyLogoLoading = false;
            })
            .addCase(setSamgSelectedCompanyThunk.fulfilled, (state, { payload }) => {
                state.companyId = payload.companyId;
            })
    }
});

export const {
    logout,
    changeCompany,
    changeCompanyUser
} = authSlice.actions;

export default authSlice.reducer;
