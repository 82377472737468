import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Image, Nav, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getCompanyUsersExtended } from "../../../api/CompanyApi";
import getIcon, { Icon, IconSize } from "../../../assets/Icons/IconClassNames";
import CompanyLogo from "../../../components/CompanyLogo/CompanyLogo";
import MainContainer from "../../../components/ContainerComponents/MainContainer";
import When from "../../../components/When";
import { CompanyRole, Role } from "../../../core/enums/enums";
import { ICompany } from "../../../core/interfaces/company";
import { ILanguages } from '../../../core/interfaces/language';
import useAccessCheck from "../../../hooks/useAccessCheck";
import { useGetUser, useIsAuthenticated } from "../../../hooks/useAuth";
import { useCNavigate } from "../../../hooks/useCNavigate";
import { useAppDispatch } from "../../../store/hooks";
import { changeCompany, changeCompanyUser, logout } from "../../../store/slices/authSlice";
import CompanyTable from "../../Company/CompanyTable";
import NewCompanyModal from "../../Company/NewCompanyModal";
import CustomNavLink from "./Components/CustomNavLink";
import Notifications from "./Components/Notifications/Notifications";

const TopMenu = () => {
    const { t, i18n } = useTranslation();
    const navigate = useCNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const isAuthenticated = useIsAuthenticated();
    const queryClient = useQueryClient();

    const [, checkAccess] = useAccessCheck();
    const [checkRole] = useAccessCheck();

    const user = useGetUser();
    const [showCompanyModal, setShowSetCompanyModal] = useState(false);
    const [showNewCompanyModal, setShowNewCompanyModal] = useState(false);
    const [collapsedMenuOpen, setCollapsedMenuOpen] = useState(false);
    const collapsedMenuRef = useRef(null);

    const handleShowCompanyModal = () => setShowSetCompanyModal(() => {
        return true;
    });
    const handleCloseCompanyModal = () => setShowSetCompanyModal(() => {
        return false;
    });

    const handleToggleCollapsedMenu = () => setCollapsedMenuOpen(collapsedMenuOpen => {
        return !collapsedMenuOpen;
    });

    const handleNavigation = (path: string) => {
        if (collapsedMenuOpen) {
            handleToggleCollapsedMenu();
        }
        if (showCompanyModal) {
            handleCloseCompanyModal();
        }
        navigate(path);
    }

    const handleChangeCompany = async (company: ICompany) => {
        dispatch(changeCompany(company));

        const companyUsers = await getCompanyUsersExtended(company.id);
        const currentUser = companyUsers!.find(s => s.userId === user.userId);
        dispatch(changeCompanyUser(currentUser));

        handleNavigation("/c/" + company.id);
        queryClient.invalidateQueries(["multiChoiceOptions"]);
    }

    function useOutsideClick(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target) && collapsedMenuOpen) {
                    handleToggleCollapsedMenu();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ref, collapsedMenuOpen]);
    }

    useOutsideClick(collapsedMenuRef);

    useEffect(() => {
        if (!isAuthenticated) {
            setCollapsedMenuOpen(() => {
                return false;
            });
        }
    }, [isAuthenticated, collapsedMenuOpen]);

    // MOVE TO TOP LAYER
    const clickLogOut = async () => {
        localStorage.clear();
        dispatch(logout());
        handleNavigation("/");
    }

    const navigateHome = () => {
        if (user.role === Role.SUPERADMIN && user.companyId) return handleNavigation(`c/${user.companyId}`);
        return handleNavigation("/");
    }

    const navigateToSupport = () => {
        if (user.companyId !== null && location.pathname.startsWith("/c/")) {
            handleNavigation(`/c/${user.companyId}/support`);
        } else {
            handleNavigation("/support");
        }
    };

    const navigateToTemplates = () => {
        handleNavigation("/templates");
    }

    const navigateToCompanyTemplates = () => {
        if (user.companyId !== null) {
            handleNavigation(`/c/${user.companyId}/templates`);
        }
    }

    const navigateToChemicals = () => {
        window.open("https://dev-imvelo.vercel.app/login", "_blank");
    }

    const profileDropdown = () => {
        const navDropdownTitle = user.firstName.charAt(0) + "" + user.lastName.charAt(0);
        return (
            <div className="circle bg-color-blue-secondary">
                <NavDropdown align="end" title={navDropdownTitle} id="nav-dropdown">
                    <Container fluid className="ps-3">
                        <Nav.Item>{user.firstName} {user.lastName}</Nav.Item>
                        <Nav.Item>{user.companyName}</Nav.Item>
                    </Container>
                    <NavDropdown.Divider />
                    <Nav style={{ width: "250px" }}>
                        {user.role === Role.SUPERADMIN && (
                            <>
                                <CustomNavLink
                                    icon={getIcon(Icon.ADMIN_DASHBOARD, IconSize.XS)}
                                    title={t('index.SAMG_DASHBOARD_1')}
                                    onClick={() => handleNavigation("/")}
                                />
                                <CustomNavLink
                                    icon={getIcon(Icon.SHUFFLE, IconSize.XS)}
                                    testId={'change_company'}
                                    title={t('index.CHANGE_COMPANY_1')}
                                    onClick={handleShowCompanyModal}
                                />
                                <CustomNavLink
                                    icon={getIcon(Icon.USER_SHIELD, IconSize.XS)}
                                    title={t('index.MANAGE_SUPERADMINS_1')}
                                    onClick={() => navigate("/superadmins")}
                                />
                                <CustomNavLink
                                    icon={getIcon(Icon.PLUS, IconSize.XS)}
                                    title={t('index.TEMPLATE_1')}
                                    onClick={() => setShowNewCompanyModal(true)}
                                />
                                <NavDropdown.Divider />
                            </>
                        )}

                        {user.role !== Role.SUPERADMIN && (user.parentCompanyId || user.hasMultipleCompanies) && (
                            <>
                                <CustomNavLink
                                    icon={getIcon(Icon.SHUFFLE, IconSize.XS)}
                                    testId={'change_company'}
                                    title={t('index.CHANGE_COMPANY_2')}
                                    onClick={handleShowCompanyModal}
                                />
                            </>
                        )}

                        {user.role !== Role.SUPERADMIN && user.parentCompanyId && (
                            <>
                                <When condition={checkRole(CompanyRole.ADMIN)}>
                                    <CustomNavLink
                                        icon={getIcon(Icon.PLUS, IconSize.XS)}
                                        title={t('index.TEMPLATE_2')}
                                        onClick={() => setShowNewCompanyModal(true)}
                                    />
                                </When>
                            </>
                        )}

                        <When condition={checkRole(CompanyRole.ADMIN)}>
                            <When condition={location.pathname.startsWith("/c/")}>
                                <CustomNavLink
                                    icon={getIcon(Icon.USER_PEN, IconSize.XS)}
                                    title={t('index.MANAGE_STAFF_1')}
                                    onClick={() => navigate(`/c/${user.companyId}/users`)}
                                />
                                <When condition={user.featureSkills}>
                                    <CustomNavLink
                                        icon={getIcon(Icon.RANKING_STAR, IconSize.XS)}
                                        title={t('index.COMPANY_SKILLS_1')}
                                        onClick={() => navigate(`/c/${user.companyId}/user-skills`)}
                                    />
                                    <CustomNavLink
                                        icon={getIcon(Icon.STAR, IconSize.XS)}
                                        title={t('index.MANAGE_SKILLS_1')}
                                        onClick={() => navigate(`/c/${user.companyId}/skills`)}
                                    />
                                </When>
                                <CustomNavLink
                                    icon={getIcon(Icon.SITEMAP, IconSize.XS)}
                                    title={t('index.MANAGE_POSITION_LEVELS_1')}
                                    onClick={() => navigate(`/c/${user.companyId}/position-levels`)}
                                />
                            </When>
                        </When>
                        <CustomNavLink
                            icon={getIcon(Icon.LOG_OUT, IconSize.XS)}
                            title={t('index.LOG_OUT_1')}
                            onClick={clickLogOut} />
                    </Nav>
                </NavDropdown>
            </div>
        )
    };

    const lngs: ILanguages = {
        sv: { nativeName: 'svenska', flagIcon: '/sv.png' },
        da: { nativeName: 'dansk', flagIcon: '/da.png' },
        en: { nativeName: 'english', flagIcon: '/en.png' },
    };

    const MenuUnauthenticated = () => (
        <>
            <Row style={{ height: "60px" }}>
                <Col xs={{ offset: 3, span: 6 }} className="text-center mt-2">
                    <Image className="cursor-pointer me-4" onClick={() => handleNavigation(("/"))} fluid width={160} src="/cc_icon.jpg" />
                    {Object.keys(lngs).map((lng: string) => (
                        <OverlayTrigger key={"ot" + lng} placement="bottom" overlay={<Tooltip id="tooltip-top">{lngs[lng].nativeName}</Tooltip>}>
                            <Image className="cursor-pointer ms-2" width={32} onClick={() => i18n.changeLanguage(lng)} src={lngs[lng].flagIcon} style={{ opacity: i18n.resolvedLanguage === lng ? 1.0 : 0.5 }} />
                        </OverlayTrigger>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 3, span: 6 }} className="text-center">
                    <Nav.Link onClick={() => navigateToSupport()}>
                        {t('TopMenuIndex.SUPPORT')}
                    </Nav.Link>
                </Col>
            </Row>
        </>
    );

    const MenuAuthenticated = () => (
        <>
            <Row style={{ height: "40px" }}>
                <Col xs={{ offset: 11, span: 1 }} className="d-flex justify-content-end align-items-center">
                    {Object.keys(lngs).map((lng: string) => (
                        <OverlayTrigger key={"ot" + lng} placement="bottom" overlay={<Tooltip id="tooltip-top">{lngs[lng].nativeName}</Tooltip>}>
                            <Image className="cursor-pointer ms-2" width={32} onClick={() => i18n.changeLanguage(lng)} src={lngs[lng].flagIcon} style={{ opacity: i18n.resolvedLanguage === lng ? 1.0 : 0.5 }} />
                        </OverlayTrigger>
                    ))}
                    <div className="ms-4 me-2">
                        <Notifications />
                    </div>
                    <div>{profileDropdown()}</div>
                </Col>
            </Row>

            <Row className={"ps-2"}>
                <Col xs={3}>
                    <When condition={!!user.companyId && location.pathname.startsWith("/c/")}>
                        <CompanyLogo
                            companyId={user.companyId}
                            rounded
                            onClick={navigateHome}
                        />
                    </When>
                </Col>
                <Col className="text-center">
                    <Nav className="justify-content-center">
                        <Nav.Link onClick={navigateHome}>{t('index.HOME_1')}</Nav.Link>
                        <Nav.Link onClick={() => navigateToSupport()}>
                            {t('TopMenuIndex.SUPPORT')}
                        </Nav.Link>
                        <When condition={checkAccess(Role.SUPERADMIN)}>
                            <Nav.Link onClick={() => navigateToTemplates()}>
                                {t('TopMenuIndex.SAMG_TEMPLATES')}
                            </Nav.Link>
                        </When>
                        <When condition={checkRole(CompanyRole.ADMIN) && !!user.companyId}>
                            <Nav.Link onClick={() => navigateToCompanyTemplates()}>
                                {t('TopMenuIndex.TEMPLATES')}
                            </Nav.Link>
                        </When>
                        <Nav.Link onClick={() => navigateToChemicals()}>
                            {t('TopMenuIndex.CHEMICALS')}
                        </Nav.Link>
                    </Nav>
                </Col>
                <Col xs={3} className="d-flex justify-content-end" style={{ height: "40px" }}>
                    <Image
                        fluid
                        className="cursor-pointer"
                        onClick={() => handleNavigation("/")}
                        width={160}
                        src="/cc_icon.jpg" />
                </Col>
            </Row>
        </>
    );

    const CollapsedMenuUnAuthenticated = () => (
        <>
            <Row style={{ height: "60px" }}>
                <Col xs={{ offset: 3, span: 6 }} className="text-center mt-2">
                    <Image className="cursor-pointer me-4" onClick={() => handleNavigation(("/"))} fluid width={160} src="/cc_icon.jpg" />
                </Col>
            </Row>
            <Row style={{ height: "60px" }}>
                <Col xs={{ offset: 3, span: 6 }} className="text-center mt-2">
                    {Object.keys(lngs).map((lng: string) => (
                        <OverlayTrigger key={"ot" + lng} placement="bottom" overlay={<Tooltip id="tooltip-top">{lngs[lng].nativeName}</Tooltip>}>
                            <Image className="cursor-pointer ms-2" width={32} onClick={() => i18n.changeLanguage(lng)} src={lngs[lng].flagIcon} style={{ opacity: i18n.resolvedLanguage === lng ? 1.0 : 0.5 }} />
                        </OverlayTrigger>
                    ))}
                </Col>
            </Row>
        </>
    )

    const CollapsedMenuAuthenticated = () => (
        <>
            <Row>
                <Col xs={3}></Col>
                <Col xs={6} className="text-center mt-3">
                    <Image
                        fluid
                        width={160}
                        src="/cc_icon.jpg"
                        onClick={() => handleNavigation("/")}
                    />
                </Col>
                <Col xs={3} className="d-flex justify-content-end mt-3 pe-3">
                    <span className="cursor-pointer" onClick={() => handleToggleCollapsedMenu()}>
                        <i className={collapsedMenuOpen ? getIcon(Icon.X_MARK, IconSize.LARGE) : getIcon(Icon.BARS, IconSize.LARGE)} />
                    </span>
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-2">
                    {Object.keys(lngs).map((lng: string) => (
                        <OverlayTrigger key={"ot" + lng} placement="bottom" overlay={<Tooltip id="tooltip-top">{lngs[lng].nativeName}</Tooltip>}>
                            <Image className="cursor-pointer ms-2" width={32} onClick={() => i18n.changeLanguage(lng)} src={lngs[lng].flagIcon} style={{ opacity: i18n.resolvedLanguage === lng ? 1.0 : 0.5 }} />
                        </OverlayTrigger>
                    ))}
                </Col>
            </Row>

            <Row className={`menu-container ${collapsedMenuOpen ? "" : "collapsed"}`}>
                <div className="menu-container-content">
                    <Nav.Link className={"ps-3 mb-2"} onClick={navigateHome}>{t('index.HOME_2')}</Nav.Link>
                    <Nav.Link className={"ps-3 mb-2"} onClick={() => navigateToSupport()}>
                        {t('index.SUPPORT_1')}
                    </Nav.Link>

                    <When condition={checkAccess(Role.SUPERADMIN)}>
                        <Nav.Link className={"ps-3 mb-2"} onClick={() => navigateToTemplates()}>
                            {t('TopMenuIndex.SAMG_TEMPLATES')}
                        </Nav.Link>
                    </When>
                    <When condition={checkRole(CompanyRole.ADMIN) && !!user.companyId}>
                        <Nav.Link className={"ps-3 mb-2"} onClick={() => navigateToCompanyTemplates()}>
                            {t('TopMenuIndex.TEMPLATES')}
                        </Nav.Link>
                    </When>
                    <Nav.Link className={"ps-3 mb-2"} onClick={() => navigateToChemicals()}>
                        {t('TopMenuIndex.CHEMICALS')}
                    </Nav.Link>


                    <div className="d-flex flex-column ps-3">
                        <hr />
                        {user.role === Role.SUPERADMIN && (
                            <>
                                <CustomNavLink
                                    icon={getIcon(Icon.ADMIN_DASHBOARD, IconSize.XS)}
                                    title={t('index.SAMG_DASHBOARD_1')}
                                    onClick={() => handleNavigation("/")}
                                />
                                <span className={"mb-2"} />
                                <CustomNavLink
                                    icon={getIcon(Icon.SHUFFLE, IconSize.XS)}
                                    title={t('index.CHANGE_COMPANY_3')}
                                    onClick={handleShowCompanyModal}
                                />
                                <hr />
                            </>
                        )}
                        <CustomNavLink
                            icon={getIcon(Icon.LOG_OUT, IconSize.XS)}
                            title={t('index.LOG_OUT_2')}
                            onClick={clickLogOut}
                        />
                    </div>
                </div>
            </Row>
        </>
    )

    return (
        <>
            <div ref={collapsedMenuRef} className="topMenu w-100"
                style={{ height: collapsedMenuOpen ? "480px" : "100px" }}>
                <MainContainer className="d-none d-md-block h-100">
                    {isAuthenticated ? <MenuAuthenticated /> : <MenuUnauthenticated />}
                </MainContainer>
                <MainContainer className="d-md-none h-100">
                    {isAuthenticated ? <CollapsedMenuAuthenticated /> : <CollapsedMenuUnAuthenticated />}
                </MainContainer>
            </div>

            <Modal
                show={showCompanyModal}
                size="xl"
                onHide={handleCloseCompanyModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('index.CHANGE_COMPANY_4')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CompanyTable
                        searchable
                        rowPointerOnHover
                        onRowClick={(selectedCompany) => handleChangeCompany(selectedCompany)}
                    />
                </Modal.Body>
            </Modal>
            <When condition={showNewCompanyModal}>
                <NewCompanyModal show={showNewCompanyModal} hide={() => setShowNewCompanyModal(false)} />
            </When>

        </>
    )
}

export default TopMenu;
